import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jcb-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  lat1 = -33.9;
  lng1 = 18.5;
  lat2 = -27.473431;
  lng2 = 153.014984;
  lat = -27.473431;
  lng = 153.014984;

  constructor() { }

  ngOnInit() {
  }

  focus(person: string) {
    switch (person) {
      case 'Jeff':
        this.lat = this.lat1;
        this.lng = this.lng1;
        break;
      case 'Mark':
        this.lat = this.lat2;
        this.lng = this.lng2;
        break;
    }
  }
}
