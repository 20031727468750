import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'jcb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email: string;
  password: string;
  user: any;
  message = '';

  constructor(private _auth: AuthService,
              private _router: Router) {
    this.user = this._auth.user;
  }

  register() {
    this._auth.signup(this.email, this.password);
    this.email = this.password = '';
  }

  login() {
    this._auth.login(this.email, this.password).then(res => {
      if (res === 'Success') {
        this._router.navigateByUrl('welcome');
      } else {
        this.message = res;
      }
    });
  }

  isLoggedIn() {
    return this._auth.isLoggedIn;
  }

  logout() {
    this._auth.logout();
  }

  reset() {
    this._auth.resetPassword(this.email);
    this.email = this.password = '';
  }
}
