import { TrackingModule } from './tracking/tracking.module';
import { RegisterModule } from './register/register.module';
import { AdminModule } from './admin/admin.module';
import { CarsModule } from './cars/cars.module';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { ItemsModule } from './items/items.module';
import { UploaderModule } from './uploader/uploader.module';
import { WelcomeModule } from './welcome/welcome.module';

export * from './admin/admin.routing.module';
export * from './cars/cars.routing.module';
export * from './home/home.routing.module';
export * from './items/items.routing.module';
export * from './login/login.routing.module';
export * from './uploader/uploader.routing.module';
export * from './register/register.routing.module';
export * from './tracking/tracking.routing.module';
export * from './welcome/welcome.routing.module';

export const PageModules = [
  AdminModule,
  CarsModule,
  HomeModule,
  ItemsModule,
  LoginModule,
  WelcomeModule,
  RegisterModule,
  TrackingModule,
  UploaderModule,
];
