import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientDetailsComponent } from './client-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDatepickerModule, MatInputModule, MatNativeDateModule, MatRadioModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  exports: [
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
  ]
})
export class ClientDetailsSharedModule { }

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    ClientDetailsSharedModule,
  ],
  declarations: [ClientDetailsComponent],
  exports: [ClientDetailsComponent],
  providers: [MatDatepickerModule]
})
export class ClientDetailsModule { }
