import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as pageRoutes from './pages';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  ...pageRoutes.registerRoutes,
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: NavigationComponent,
    // canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      ...pageRoutes.adminRoutes,
      ...pageRoutes.carsRoutes,
      ...pageRoutes.homeRoutes,
      ...pageRoutes.itemsRoutes,
      ...pageRoutes.welcomeRoutes,
      ...pageRoutes.trackingRoutes,
      ...pageRoutes.uploaderRoutes,
    ],
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {useHash: true, enableTracing: false})],
  exports: [RouterModule],
  providers: [
    AuthGuard,
  ]
})

export class AppRoutingModule { }
