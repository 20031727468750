import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatStepperModule, MatListModule  } from '@angular/material';

@NgModule({
  exports: [
    MatListModule,
    FlexLayoutModule,
    MatStepperModule,
    BrowserAnimationsModule,
  ],
})
export class SharedRegisterModule {}

@NgModule({
  imports: [
    CommonModule,
    SharedRegisterModule,
  ],
  declarations: [RegisterComponent]
})
export class RegisterModule { }
