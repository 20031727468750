import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../../services/auth/auth.service';
import { map } from 'rxjs/operators';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'jcb-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  allItems: any[];
  items: any[];
  displayedColumns = ['BRAND', 'CATEGORY', 'Level', 'Name', 'Guide Price'];
  dataSource;
  pageSize = 25;
  listLength = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];


  constructor(private db: AngularFireDatabase,
              private _auth: AuthService) { }

  ngOnInit() {
    this._auth.loadShow();
    this.db.list('/items').valueChanges().subscribe(
      val => {
        this.allItems = val;
        this.listLength = val.length;
        this.getPage({ pageIndex: 0, pageSize: this.pageSize });
        this._auth.loadHide();
    });
  }

  getPage(pageEvent: PageEvent | any) {
    const page = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    const start = page * this.pageSize;
    const end = start + this.pageSize;
    this.items = this.allItems.slice(start, end);
    this.dataSource = this.items;
  }

  get (startKey) {
    console.log({startKey});
    console.log(this.db.list('/items', ref => {
      return ref.startAt((startKey - 1) * this.pageSize)
        .limitToFirst(this.pageSize);
      }).valueChanges().subscribe((res: any[]) => {
      this.items = res;
      this.dataSource = this.items;
      this._auth.loadHide();
      return res;
    }, e => {
      this._auth.loadHide();
    }));
  }

  log(data) {
    console.log(data);
  }
}
