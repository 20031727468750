import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatProgressBarModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@NgModule({
  exports: [
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
  ]
})
export class NavigationSharedModule { }

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NavigationSharedModule,
  ],
  providers: [AuthService],
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
})
export class NavigationModule { }
