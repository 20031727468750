// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    firebase: {
      apiKey: 'AIzaSyAI7F275domLL5Zp280qOjgMRncDNS4ocU',
      authDomain: 'fir-test-c2371.firebaseapp.com',
      databaseURL: 'https://fir-test-c2371.firebaseio.com',
      projectId: 'fir-test-c2371',
      storageBucket: 'fir-test-c2371.appspot.com',
      messagingSenderId: '101620538941'
    },
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
