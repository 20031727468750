import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarsComponent } from './cars.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatInputModule, MatFormFieldModule, MatCardModule, MatButtonModule, MatStepperModule,
  MatListModule, MatTableModule, MatSortModule, MatProgressBarModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AuthService } from '../../services/auth/auth.service';

@NgModule({
  exports: [
    // FormsModule,
    // MatCardModule,
    // MatInputModule,
    // MatButtonModule,
    MatSortModule,
    MatListModule,
    MatTableModule,
    FlexLayoutModule,
    MatStepperModule,
    MatProgressBarModule,
    // MatFormFieldModule,
    // ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
})
export class SharedCarsModule {}

@NgModule({
  imports: [
    CommonModule,
    SharedCarsModule,
    AngularFireDatabaseModule, // for database
  ],
  declarations: [CarsComponent],
  exports: [CarsComponent],
  providers: [AuthService],
})
export class CarsModule { }
