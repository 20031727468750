import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  user;
  redirectUrl: string;
  private loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();
  isLoggedIn = false;

  constructor(private firebaseAuth: AngularFireAuth, private router: Router) {

    this.firebaseAuth.authState.subscribe(auth => {
      if (!auth) {
        // Redirect non-logged in user back to home
        this.router.navigateByUrl('login');
      }
      if (auth) {
        this.user = auth;
        this.isLoggedIn = true;
        if (this.redirectUrl) {
          this.router.navigateByUrl(this.redirectUrl);
        } else {
          // Redirect logged in user here
          this.router.navigateByUrl('items');
        }
      }
    });
  }

  loadShow() {
    this.loaderSubject.next(true);
  }
  loadHide() {
    this.loaderSubject.next(false);
  }

  signup(email: string, password: string) {
    this.firebaseAuth
      .auth
      .createUserWithEmailAndPassword(email, password)
      .then(value => {
        console.log('Success!', value);
        return 'Success';
      })
      .catch(err => {
        console.log('Something went wrong:', err.message);
        return err.message;
      });
  }

  login(email: string, password: string) {
    return this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        console.log('Nice, it worked!', value);
        return 'Success';
      })
      .catch(err => {
        console.log('Something went wrong:', err.message);
        return err.message;
      });
  }

  logout() {
    this.isLoggedIn = false;
    this.firebaseAuth.auth.signOut();
    this.router.navigateByUrl('login');
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    return this.firebaseAuth.auth
      .sendPasswordResetEmail(email);
      // .then(() => this.notify.update('Password update email sent', 'info'))
      // .catch(error => this.handleError(error));
  }
}
