import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jcb-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public section: 'user-details' | '' = '';

  constructor() { }

  ngOnInit() {
  }

}
