import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemsComponent } from './items.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatInputModule, MatFormFieldModule, MatCardModule, MatButtonModule, MatStepperModule,
  MatListModule, MatTableModule, MatSortModule, MatProgressBarModule, MatTooltipModule, MatPaginatorModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AuthService } from '../../services/auth/auth.service';

@NgModule({
  exports: [
    // FormsModule,
    // MatCardModule,
    // MatInputModule,
    // MatButtonModule,
    MatSortModule,
    MatListModule,
    MatTableModule,
    FlexLayoutModule,
    MatStepperModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressBarModule,
    // MatFormFieldModule,
    // ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
})
export class SharedItemsModule {}

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    SharedItemsModule,
    AngularFireDatabaseModule, // for database
  ],
  declarations: [ItemsComponent],
  exports: [ItemsComponent],
  providers: [AuthService],
})
export class ItemsModule { }
