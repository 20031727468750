import { Car } from '../../models/car';
import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { FirebaseListObservable } from '@angular/fire/database-deprecated';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'jcb-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.scss']
})
export class CarsComponent implements OnInit {
  items: Car[];
  displayedColumns = ['class', 'doors', 'hasAC', 'id', 'imageStoragePath', 'imageUrl', 'luggage', 'name', 'price', 'seats', 'transmission'];
  dataSource;

  constructor(private db: AngularFireDatabase,
              private _auth: AuthService) { }

  ngOnInit() {
    this.get();
  }
  get () {
    this._auth.loadShow();
    // const collection: AngularFirestoreCollection<Car> = this.db.list('cars');
    // const collection$: Observable<Car> = collection.valueChanges();
    // collection$.subscribe(data => console.log(data) );
    this.db.list('/cars').valueChanges().subscribe((res: Car[]) => {
      // res.forEach((k: Car) => console.log(Object.keys(k)));
      // console.log(res);
      this.items = res;
      this.dataSource = this.items;
      this._auth.loadHide();
      // this.items = JSON.parse(JSON.stringify(res));
    }, e => {
      // console.log(e);
      this._auth.loadHide();
    });
    // this.items = JSON.parse(JSON.stringify(this.db.list('/cars').valueChanges()));
  }
}
