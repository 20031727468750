import { Component, OnInit, ViewChild } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'jcb-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @ViewChild('file') file;
  @ViewChild('delimiter') delimiter;
  @ViewChild('tableName') tableName;

  public fileSizeLimit = 50;
  public uploading = false;
  public uploadSuccessful = false;
  public output = '';

  public files: Set<File>;

  constructor(private papa: Papa,
              private db: AngularFireDatabase,
              private _auth: AuthService) { }

  ngOnInit() {
    this.files = new Set();
  }

  public checkFileSize(files: FileList): boolean {
    const check = this.fileSizeLimit * 1024 * 1024;

    for (const key in files) {
      if (files[key].size > check) {
        return false;
      }
    }

    return true;
  }

  public onFilesAdded(): void {

    if (this.checkFileSize(this.file.nativeElement.files)) {

      const files: { [key: string]: File } = this.file.nativeElement.files;
      Object.keys(files).forEach(key => {
        if (!isNaN(parseInt(key, 10))) {
          this.files.add(files[key]);
        }
      });

      this.processUpload();
    } else {
      console.log('The file you have selected is too large.');
    }
  }

  public processUpload() {
    const options = {
      complete: (results, file) => {
        console.log('Parsed: ', results, file);
        this.output = results['data'];
      },
      delimiter: this.delimiter.nativeElement.value,
      header: true,
    };
    if (this.files.size) {

      this.files.forEach(file => {
        this.uploading = true;

        const reader = new FileReader();
        reader.onload = (e: any) => {
          console.log('csv content', e.target.result) ;
          this.papa.parse(e.target.result, options);
        };
        reader.readAsText(file);

        this.uploadSuccessful = true;
        this.uploading = false;
      });
    }
  }
  newTable () {
    console.log(`saving to /${this.tableName.nativeElement.value} with ${this.output}`);
    this.db.database.ref('/' + this.tableName.nativeElement.value).set(this.output);
  }
  delTable () {
    this.db.database.ref('/' + this.tableName.value).remove();
  }
}
