import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingComponent } from './tracking.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDe3XC2KHCGuU6wTqK_rBsom1tydiNvYic'
    }),
  ],
  declarations: [TrackingComponent]
})
export class TrackingModule { }
