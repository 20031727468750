import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { ClientDetailsModule } from './client-details/client-details.module';
import { MatRadioModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

@NgModule({
  exports: [
    MatRadioModule,
  ],
})
export class AdminSharedModule { }

@NgModule({
  declarations: [AdminComponent],
  imports: [
    FormsModule,
    CommonModule,
    AdminSharedModule,
    ClientDetailsModule,
  ],
})
export class AdminModule { }
